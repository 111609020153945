export default {
  aiSearch: 'AI Search',
  loading: 'Loading...',
  noData: 'No Data',
  newProblem: 'New Question',
  lately: 'Recent Conversations',
  more: 'View More',
  link: 'Link',
  source: 'Source',
  all: 'All',
  doc: 'Document',
  copyTip: 'Copied to Clipboard',
  answer: 'Answer',
  asking: 'Continue Asking',
  image: 'Image',
  enclosure: 'Attachment',
};
