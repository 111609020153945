export default {
  aiSearch: 'AI 搜尋',
  loading: '加載中...',
  noData: '無數據',
  newProblem: '新問題',
  lately: '最近的對話',
  more: '查看更多',
  link: '鏈接',
  source: '來源',
  all: '全部',
  doc: '文件',
  copyTip: '已複製到剪貼板',
  answer: '答案',
  asking: '繼續提問',
  image: '圖片',
  enclosure: '附件',
};
