export default {
  aiSearch: 'Búsqueda AI',
  loading: 'Cargando...',
  noData: 'Sin datos',
  newProblem: 'Nueva pregunta',
  lately: 'Conversaciones recientes',
  more: 'Ver más',
  link: 'Enlace',
  source: 'Fuente',
  all: 'Todo',
  doc: 'Documento',
  copyTip: 'Copiado al portapapeles',
  answer: 'Respuesta',
  asking: 'Seguir preguntando',
  image: 'Imagen',
  enclosure: 'Adjunto',
};
