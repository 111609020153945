import { QueryBase } from './origin-fetch';
import { reactive } from 'vue';
export const errorInfo = reactive({
  code: 0,
  message: '',
});
export default (res: QueryBase<any>, autoTip: boolean) => {
  // const redirectCodes = [20026, 40101, 40102, 40103, 40106, 40107];
  // const noPermissionCodes = [40100, 40104];
  // if (redirectCodes.includes(res.code)) {
  //   location.href = '/accounts/signin';
  // } else if (noPermissionCodes.includes(res.code)) {
  //   const current = history.state.current as string;
  //   location.hash = `${current.substring(0, current.indexOf('/', 15))}/403`;
  // }
  const redirectCodes = [40107, 40329]; // 40107已过期 40329 未打开

  if (redirectCodes.includes(res.code)) {
    errorInfo.code = res.code;
    errorInfo.message = res.message;
  }
  throw new Error(res.message);
};
