export default {
  aiSearch: 'AI 搜索',
  loading: '加载中...',
  noData: '暂无数据',
  newProblem: '新问题',
  lately: '最近对话',
  more: '查看更多',
  link: '链接',
  source: '来源',
  all: '全部',
  doc: '文档',
  copyTip: '已复制到剪贴板',
  answer: '回答',
  asking: '继续问',
  image: '图片',
  enclosure: '附件',
};
