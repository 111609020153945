import { getStorage, setStorage } from '@/utils/storage';
import { generateRandomString } from '@/utils/randomString';
import getChatType, { getUrlQuery } from '@/utils/get-chat-type';

export const getAccountId = () => {
  let accountId = '';
  if (['embed', 'widget'].includes(getChatType())) {
    if (!getUrlQuery('user_id') && !getStorage<string>('embed_accountId')) {
      setStorage({
        key: 'embed_accountId',
        value: generateRandomString(20),
      });
    }
    accountId = getUrlQuery('user_id') || getStorage<string>('embed_accountId')!;
  } else {
    if (!getStorage<string>('AccountId')) {
      setStorage({
        key: 'AccountId',
        value: generateRandomString(20),
      });
    }
    accountId = getStorage<string>('AccountId')!;
  }
  return accountId;
};

export const getClientId = () => {
  if (!getStorage<string>('embed_accountId')) {
    const id = generateRandomString(20);
    setStorage({
      key: 'embed_accountId',
      value: id,
    });
    return id;
  }
  return getStorage<string>('embed_accountId')!;
};
