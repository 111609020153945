/*
 * @author: yangjh
 * @e-mail: yangjh@jiguang.cn
 * @date: 2023/05/13 16:10:34
 * @description: 多语言hook
 */
import type enUS from '@/i18n/en_US';
import { useI18n } from 'vue-i18n';
import { setCookie } from '@/utils/cookie';
export type localeType = 'en_US' | 'ja_JP' | 'zh_CN' | 'zh_TW';
export default () => {
  const i18n = useI18n<{ message: typeof enUS }, localeType>();
  const setLocale = (locale: localeType) => {
    i18n.locale.value = locale;
    setCookie('i18n_redirected', locale);
  };
  const showDateTime = (d?: Date) => {
    if (!d) d = new Date();
    if (i18n.locale.value === 'en_US') {
      return (
        d.getDate() +
        '/' +
        (d.getMonth() + 1) +
        '/' +
        d.getFullYear() +
        ' ' +
        d.toLocaleString().split(' ')[1]
      );
    } else {
      return d.toLocaleString();
    }
  };
  return Object.assign(i18n, { setLocale, showDateTime });
};
