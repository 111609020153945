import { CodeMsg, RespObject } from '@/api/gen/model';
export { default as getData } from './method-get';
export { default as deleteData } from './method-delete';
export { default as postData } from './method-post';
export { default as putData } from './method-put';

interface TicketVO {
  ticket: string;
}

function isUndefinedOrNull(value: any) {
  return value === undefined || value === null;
}

export const withParam = (url: string, param: Record<string, any>) => {
  Object.keys(param ?? {}).forEach(item => {
    if (isUndefinedOrNull(param?.[item])) delete param?.[item];
  });
  const _param = param ? `?${new URLSearchParams(param)}` : '';
  return url + _param;
};

export type ListenStreamHandler<T> = {
  onMessage: (data: T) => void;
  onEnd?: (e: Event) => void;
  onError?: (codeMsg: CodeMsg) => void;
};

export const listenStream = <T>(url: string, handler: ListenStreamHandler<T>) => {
  const eventSource = new EventSource(url);
  eventSource.onerror = (e: Event) => {
    handler.onEnd && handler.onEnd(e);
  };
  eventSource.onmessage = (message: MessageEvent<string>) => {
    let data: any = message.data;
    try {
      data = JSON.parse(message.data);
      if (data.code && !String(data.code).startsWith('200')) {
        handler.onError && handler.onError(data);
      }
    } catch (e) {}
    if (data.code !== 200200) {
      // 心跳忽略
      handler.onMessage(data);
    }
  };
  const stopHandler = () => {
    eventSource.close();
  };
  return stopHandler;
};

export type StreamHandler<S, T> = {
  onMessage: (data: T) => void;
  onStart?: (s: S) => void;
  onEnd?: () => void;
  onError?: (codeMsg: CodeMsg) => void;
};

export const mapStream = <S, T>(
  url: string,
  handler: StreamHandler<S, T>,
  promise: Promise<RespObject<TicketVO>>,
) => {
  let eventSource: null | EventSource = null;
  let needStop = false;
  promise
    .then(res => {
      handler.onStart && handler.onStart(res as any);
      if (needStop) return;
      if (res.code < 0) {
        handler.onError && handler.onError(res);
        return;
      }
      eventSource = new EventSource(`${url}?ticket=${res.data.ticket}`);
      eventSource.onerror = (e: Event) => {
        eventSource && eventSource.close();
        handler.onEnd && handler.onEnd();
      };
      eventSource.onmessage = (message: MessageEvent<string>) => {
        let data: any = message.data;
        try {
          data = JSON.parse(message.data);
          if (data.code < 0 && !String(data.code).startsWith('200')) {
            handler.onError && handler.onError(data);
          }
        } catch (e) {}
        handler.onMessage(data);
      };
    })
    .catch(e => {
      handler.onError && handler.onError({ code: -1, message: e.message });
    });
  const stopHandler = () => {
    needStop = true;
    eventSource && eventSource.close();
  };
  return stopHandler;
};
