<template>
  <ConfigProvider :locale="localeData">
    <RouterView></RouterView>
  </ConfigProvider>
</template>
<script setup lang="ts">
import { computed, h, watch } from 'vue';
import { ConfigProvider, Spin } from 'ant-design-vue';
import en_US from 'ant-design-vue/es/locale/en_US';
import zh_CN from 'ant-design-vue/es/locale/zh_CN';
import zh_TW from 'ant-design-vue/es/locale/zh_TW';
import ja_JP from 'ant-design-vue/es/locale/ja_JP';
import { useVueI18n } from '@/hooks';
import { Loading3QuartersOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { errorInfo } from '@/utils/fetch/error-interceptor';
Spin.setDefaultIndicator({
  indicator: h(Loading3QuartersOutlined, { class: 'custom-loading', spin: true }),
});
const localeData = computed(() => {
  switch (locale.value) {
    case 'en_US':
      return en_US;
    case 'zh_CN':
      return zh_CN;
    case 'zh_TW':
      return zh_TW;
    case 'ja_JP':
      return ja_JP;
    default:
      return en_US;
  }
});
const { locale } = useVueI18n();
const router = useRouter();

watch(
  () => errorInfo.code,
  () => {
    if (errorInfo.code && errorInfo.message) {
      errorInfo.code = 0;
      router.push({ name: 'error-page' });
    }
  },
);
</script>
<style scoped lang="less"></style>
