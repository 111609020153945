/*
 * @author: yangjh
 * @e-mail: yangjh@jiguang.cn
 * @date: 2023/03/02 10:41:39
 * @description: 发送网络请求
 */

import { nextTick, ref, Ref } from 'vue';

type OptionsF<Data, Param, Format> = Partial<Options<Data, Param>> & {
  format: (data: Data) => Format;
};
type Options<Data, Param> = {
  manual: boolean;
  onBefore: (param: Param) => void;
  onSuccess: (data: Data, param: Param) => void;
  onError: (e: Error, param: Param) => void;
  onFinally: (param: Param, data?: Data, e?: Error) => void;
};

export default function useRequest<Data, Param extends Array<any>, F>(
  req: (...args: Param) => Promise<Data>,
  options: OptionsF<Data, Param, F>,
): {
  data: Ref<F>;
  loading: Ref<boolean>;
  run: (...args: Param) => Promise<F>;
  error: Ref<Error>;
};

export default function useRequest<Data, Param extends Array<any>>(
  req: (...args: Param) => Promise<Data>,
  options?: Partial<Options<Data, Param>>,
): {
  data: Ref<Data>;
  loading: Ref<boolean>;
  run: (...args: Param) => Promise<Data>;
  error: Ref<Error>;
};

export default function useRequest<Data, Param extends Array<any>, F>(
  req: (...args: Param) => Promise<Data>,
  options?: Partial<Options<Data, Param>> | OptionsF<Data, Param, F>,
): any {
  const loading = ref<boolean>(false);
  const error = ref<Error>();
  const data = ref<F>();
  const run: typeof req = (...param) =>
    new Promise((resolve, reject) => {
      options && options.onBefore && options.onBefore(param);
      loading.value = true;
      req(...param)
        .then(res => {
          if (Object.prototype.hasOwnProperty.call(options ?? {}, 'format')) {
            (data.value as any) = (options as any).format(res);
          } else {
            (data.value as any) = res;
          }
          nextTick(() => {
            options && options.onSuccess && options.onSuccess(res, param);
            options && options.onFinally && options.onFinally(param, res);
            resolve(res);
            loading.value = false;
          });
        })
        .catch(err => {
          error.value = err;
          options && options.onError && options.onError(err, param);
          options && options.onFinally && options.onFinally(param, undefined, err);
          reject(err);
          loading.value = false;
        });
    });
  if (!(options?.manual ?? false)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    run();
  }
  return { data, loading, run, error };
}
