import { createApp } from 'vue';
import App from './App.vue';
import i18n from '@/i18n';
import './theme/index.less';
import './theme/chat/tailwind.css';
import router from './router';
const bootstrap = async () => {
  const app = createApp(App);
  app.use(i18n).use(router);
  app.mount('#app');
};
bootstrap();
