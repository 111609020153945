import { useRequest } from '@/hooks';
import { takeHistoryConfig } from '@/api/gen/bot-share-controller';
import { ref } from 'vue';
import {
  BotHistoryShareConfig,
  BotHistoryShareDetail,
  BotEmbedShareDetail,
  BotChatShareDetail,
  BotChatShareConfig,
  BotEmbedShareConfig,
} from '@/api/gen/model';
import { setStorage } from '@/utils/storage';
type ConfigDetail = BotHistoryShareDetail & BotEmbedShareDetail & BotChatShareDetail;

export interface HistoryConfigInfo extends BotHistoryShareConfig {
  errorMsg?: string;
}

export type ConfigInfo = (HistoryConfigInfo | BotChatShareConfig | BotEmbedShareConfig) & {
  detail?: ConfigDetail;
  errorMsg?: string;
};
const historyInfo = ref<HistoryConfigInfo>({});
export default () => {
  const getHistory = (callback?: (data: BotHistoryShareConfig | undefined) => void) => {
    const { loading } = useRequest(takeHistoryConfig, {
      onSuccess: ({ data }) => {
        historyInfo.value = data;
        setStorage({
          key: 'i18n_redirected',
          value: data.locale,
        });
        callback && callback(data);
      },
      onError: (res: any) => {
        historyInfo.value = { errorMsg: res.message };
        callback && callback(undefined);
      },
    });
    return { loading };
  };
  return { historyInfo, getHistory };
};
