export default {
  'app.copy': '複製',
  'app.myBot.chat.clean.tips': '已清空聊天記錄，重新開始也是一種好的選擇！',
  'app.myBot.chat.context.unLink': '非常抱歉，該鏈接已到期失效！',
  'app.myBot.chat.clean.lookMore': '查看更多',
  'app.myBot.chat.timeout.loading': '加載中',
  'app.myBot.chat.timeout.placeholder': '開始對話吧（Enter發送，Ctrl + Enter換行）',
  'app.myBot.chat.timeout.placeholderMobile': '開始對話吧',
  'app.myBot.chat.stop': '停止回答',
  'app.myBot.chat.goConversation': '去對話',
  'app.myBot.chat.speech.recognition.progress': '錄音中…',
  'app.myBot.chat.speech.recognition.error': '語音識別失敗，請重試或者使用文字！',
  'app.myBot.chat.speech.converting.text': '識別中…',
  'app.myBot.chat.record.error': '麥克風已被禁用請檢查瀏覽器站點設置',
  'app.plugin.PluginCallSuccess': '插件調用成功',
  'app.plugin.PluginCallWait': '插件調用等待',
  'app.plugin.PluginCallFail': '插件調用失敗',
  'app.myBot.chat.question.title': '試著問問',
  'app.home.embed.deny': '小部件應嵌入到某個網站中使用',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.error':
    '上傳錯誤',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading':
    '上傳中',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading1':
    '只能上傳jpg、png、gif、bmp、webp格式的圖片',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading2':
    '圖片大小不能超過20MB',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading3':
    '最多支持上傳{imageLimit}張圖片',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading4':
    '該BOT所選擇的模型版本不支持圖片輸入',
};
