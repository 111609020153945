import { ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { getDefaultLocale } from '@/i18n/index';
import { getAccountId } from '@/utils/accountId';
import { getUrlParams } from '@/utils/randomString';

const ticket = getUrlParams();
export default () => {
  const audioRef = ref<any>(null);
  const audioLoading = ref(false);
  const audioPlaying = ref(false);
  const audioduration = ref<number>(0); //总时长
  const audiocurrentTime = ref<number>(0); //当前播放时长
  const audiominutes = ref<string>('00');
  const audioseconds = ref<string>('00');
  const audioController = ref<AbortController>(new AbortController());
  const { locale } = useI18n();
  let buffer: Uint8Array | null = null;

  function readAudioStream(
    audio: any,
    stream: any,
    contentType = 'audio/mpeg',
  ): Promise<Uint8Array> {
    // Create media source and play audio
    const ms = new MediaSource();
    const url = URL.createObjectURL(ms);
    audio.src = url;
    audio.play();

    let u8Arr: Uint8Array = new Uint8Array();
    return new Promise<Uint8Array>(async (resolve, reject) => {
      // Async to read data from ms
      await new Promise(resolve => {
        ms.onsourceopen = resolve;
      });

      const sourceBuffer = ms.addSourceBuffer(contentType);

      const reader = stream.getReader();

      // read stream
      try {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            resolve(u8Arr);
            if (sourceBuffer.updating) {
              await new Promise(resolve => (sourceBuffer.onupdateend = resolve));
            }
            ms.endOfStream();
            return;
          }

          u8Arr = new Uint8Array([...u8Arr, ...value]);

          await new Promise(resolve => {
            sourceBuffer.onupdateend = resolve;
            sourceBuffer.appendBuffer(value.buffer);
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  function playAudioBuffer(audio: any, buffer: Uint8Array) {
    const audioUrl = URL.createObjectURL(new Blob([buffer], { type: 'audio/mpeg' }));
    audio.src = audioUrl;
    audio.play();
  }
  const playAudio = async (text: any, messageId: any, ProjectId: any, BotId: any) =>
    new Promise<{ buffer?: Uint8Array; code?: number; message?: string }>(
      async (resolve, reject) => {
        text = text.replace(/\\n/g, '\n');
        try {
          // tts play
          if (audioRef.value) {
            audioLoading.value = true;

            /* buffer tts */
            if (buffer) {
              playAudioBuffer(audioRef.value, buffer);
              audioLoading.value = false;
              return resolve({ buffer });
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            audioController.value.current = new AbortController();
            /* request tts */
            const response = await fetch(
              `/api/share/bot/message/p-${ProjectId}/b-${BotId}/text-to-audio`,
              {
                credentials: 'include',
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                signal: audioController.value.current.signal,
                body: JSON.stringify({
                  messageId,
                  text,
                }),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                headers: Object.assign({
                  // 预制头
                  Locale: getDefaultLocale(),
                  Authorization: 'Basic ' + btoa(getAccountId() + ':' + ticket),
                  'Content-Type': 'application/json',
                }),
              },
            );
            audioLoading.value = false;
            if (response.headers.get('content-type') === 'application/json') {
              //普通报错消息
              const data = await response.json();
              resolve(data);
              return;
            }
            if (!response.body || !response.ok) {
              const data = await response.json();
              return reject(data);
            }
            buffer = await readAudioStream(audioRef.value, response.body, 'audio/mpeg');

            resolve({
              buffer,
            });
          } else {
            // window speech
            window.speechSynthesis?.cancel();
            const msg = new SpeechSynthesisUtterance(text);
            const voices = window.speechSynthesis?.getVoices?.() || []; // 获取语言包
            const voice = voices.find(item => {
              return item.lang === 'zh-CN';
            });
            if (voice) {
              msg.onstart = () => {
                audioPlaying.value = true;
              };
              msg.onend = () => {
                audioPlaying.value = false;
                msg.onstart = null;
                msg.onend = null;
              };
              msg.voice = voice;
              window.speechSynthesis?.speak(msg);
            }
            resolve({});
          }
        } catch (error) {
          reject(error);
        }
        audioLoading.value = false;
      },
    );
  function cancelAudio() {
    if (audioRef.value) {
      audioRef.value.pause();
      audioRef.value.src = '';
    }
    window.speechSynthesis?.cancel();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    audioController.value.current?.abort();
    audioPlaying.value = false;
  }
  function initBuffAudio(msgId: string) {
    if (!audioRef.value) {
      audioRef.value = new Audio();
      audioRef.value.controls = true;
      audioRef.value.preload = 'metadata';
      audioRef.value.style.width = '0px';
      audioRef.value.style.display = 'none';
      audioRef.value.id = msgId;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      audioRef.value.addEventListener('timeupdate', e => {
        audiocurrentTime.value = audioRef.value.currentTime;
        audiominutes.value = String(Math.floor(audiocurrentTime.value / 60));
        audioseconds.value = String(
          Math.floor(audiocurrentTime.value % 60) < 10
            ? '0' + Math.floor(audiocurrentTime.value % 60)
            : Math.floor(audiocurrentTime.value % 60),
        );
      });
      //获取总时长
      audioRef.value.addEventListener('loadedmetadata', () => {
        audioduration.value = audioRef.value.duration;
      });
      //播放完成
      audioRef.value.addEventListener('ended', () => {
        audiocurrentTime.value = 0;
      });
      const audioList = document.querySelectorAll('audio');
      audioList.forEach(item => {
        item.pause();
        item.currentTime = 0;
      });

      document.body.appendChild(audioRef.value);
    }
    return audioRef.value;
  }
  //监听audio对象

  const monitorAudio = () => {
    return watchEffect(() => {
      if (audioRef.value) {
        audioRef.value.onplay = () => {
          audioPlaying.value = true;
        };
        audioRef.value.onended = () => {
          audioPlaying.value = false;
        };
        audioRef.value.onerror = () => {
          audioPlaying.value = false;
        };
        audioRef.value.oncancel = () => {
          audioPlaying.value = false;
        };
      }
      const listen = () => {
        cancelAudio();
      };
      window.addEventListener('beforeunload', listen);
      return () => {
        if (audioRef.value) {
          audioRef.value.onplay = null;
          audioRef.value.onended = null;
          audioRef.value.onerror = null;
        }
        cancelAudio();
        window.removeEventListener('beforeunload', listen);
      };
    });
  };
  //销毁audio对象
  const destroyAudio = () => {
    if (audioRef.value) {
      audioRef.value = undefined;
    }
  };
  return {
    audioRef,
    audioduration,
    audiocurrentTime,
    audiominutes,
    audioseconds,
    audioLoading,
    audioPlaying,
    playAudio,
    initBuffAudio,
    monitorAudio,
    destroyAudio,
  };
};
