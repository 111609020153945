export default {
  aiSearch: 'AI検索',
  loading: '読み込み中...',
  noData: 'データなし',
  newProblem: '新しい質問',
  lately: '最近の会話',
  more: 'もっと見る',
  link: 'リンク',
  source: 'ソース',
  all: 'すべて',
  doc: 'ドキュメント',
  copyTip: 'クリップボードにコピーしました',
  answer: '回答',
  asking: '質問を続ける',
  image: '画像',
  enclosure: '添付ファイル',
};
