import originFetch from './origin-fetch';
export default async <T>(
  url: string,
  param?: Record<string, any>,
  autoTip?: boolean,
  headers?: HeadersInit,
  timeout?: number,
) => {
  Object.keys(param ?? {}).forEach(item => {
    if (!param?.[item]) delete param?.[item];
  });
  const _param = param ? `?${new URLSearchParams(param)}` : '';
  return originFetch<T>(`${url}${_param}`, 'GET', null, headers, autoTip, () => {}, timeout);
};
