export type StorageContext = {
  key: string;
  value: any;
  timeout?: number;
  once?: boolean;
};

export type StorageType = 'local' | 'session';

const getStorageAction = (type: StorageType = 'local'): Storage =>
  type === 'local' ? window.localStorage : window.sessionStorage;

export function setStorage(context: StorageContext, type?: StorageType) {
  getStorageAction(type).setItem(context.key, JSON.stringify(context));
}

export function getStorage<T>(key: string, type?: StorageType): T | null {
  const storageAction = getStorageAction(type);
  const storageData = storageAction.getItem(key);
  if (storageData) {
    try {
      const dataJson = JSON.parse(storageData) as StorageContext;
      if (dataJson.timeout && Date.now() > dataJson.timeout) {
        storageAction.removeItem(key);
        return null;
      }

      if (dataJson.once) {
        storageAction.removeItem(key);
      }

      return dataJson.value;
    } catch (e) {
      return null;
    }
  }

  return null;
}

export function removeStorage(key: string, type?: StorageType) {
  const storageAction = getStorageAction(type);
  return storageAction.removeItem(key);
}
