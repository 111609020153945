/**
 * bot分享
 */

import type {
  BotChatShareConfig,
  BotEmbedShareConfig,
  BotHistoryShareConfig,
  ChatRecordSharePageForm,
  MessageVO,
  Page,
  RespObject,
} from './model';
import { getData } from '@/utils/fetch';

/**
 * 获取分享Bot配置
 */
export const takeChatConfig = () => {
  return getData<RespObject<BotChatShareConfig>>('/api/share/bot/share/chat/config/take', {});
};

/**
 * 获取嵌入Bot配置
 */
export const takeEmbedConfig = () => {
  return getData<RespObject<BotEmbedShareConfig>>('/api/share/bot/share/embed/config/take', {});
};

/**
 * 获取分享聊天记录配置
 */
export const takeHistoryConfig = () => {
  return getData<RespObject<BotHistoryShareConfig>>('/api/share/bot/share/history/config/take', {});
};

/**
 * 获取分享聊天记录 分页
 * @param form form
 */
export const recordPage = (form: ChatRecordSharePageForm) => {
  return getData<RespObject<Page<MessageVO>>>('/api/share/bot/share/history/record/page', form);
};
