import { createI18n } from 'vue-i18n';
import en_US from './en_US';
import zh_CN from './zh_CN';
import zh_TW from './zh_TW';
import ja_JP from './ja_JP';
import es_ES from './es_ES';
import { getCookie } from '@/utils/cookie';
import { getStorage } from '@/utils/storage';

export const getDefaultLocale = (): string => {
  // navigator.language 返回符合 ISO 639-1的语言代码
  // eg：zh zh-CN zh-TW zh-HK en en-US en-CA
  const language = navigator.language;
  const [lang, district] = language?.split('-') ?? [];
  if (getCookie('i18n_redirected')) {
    return getCookie('i18n_redirected');
  } else if (getStorage('i18n_redirected')) {
    return getStorage('i18n_redirected')!;
  } else {
    // console.log(lang, district, 888);
    switch (lang) {
      // 兼容 zh zh-CN zh-TW zh-HK
      case 'zh': {
        const zhLangMap = {
          CN: 'zh_CN',
          HK: 'zh_TW',
          TW: 'zh_TW',
        };
        return zhLangMap?.[district as 'CN' | 'HK' | 'TW'] ?? 'zh_CN';
      }
      case 'en':
        return 'en_US';
      case 'ja':
        return 'ja_JP';
      case 'es':
        return 'es_ES';
      default:
        return 'en_US';
    }
  }
};

export default createI18n({
  locale: getDefaultLocale(),
  allowComposition: true,
  messages: { en_US, zh_CN, zh_TW, ja_JP, es_ES },
  fallbackLocale: 'en_US',
});
