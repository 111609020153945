export default {
  'app.copy': 'Copy',
  'app.myBot.chat.clean.tips': 'Chat history cleared. Starting fresh can be good!',
  'app.myBot.chat.context.unLink': 'Sorry, the link has expired!',
  'app.myBot.chat.clean.lookMore': 'View More',
  'app.myBot.chat.timeout.loading': 'Loading...',
  'app.myBot.chat.timeout.placeholder':
    'Start a conversation now! (Enter to send, Ctrl + Enter to new line)',
  'app.myBot.chat.timeout.placeholderMobile': 'Start a conversation now!',
  'app.myBot.chat.stop': 'Stop Answering',
  'app.myBot.chat.goConversation': 'Start',
  'app.myBot.chat.speech.recognition.progress': 'Recording...',
  'app.myBot.chat.speech.recognition.error':
    'Identification failed. Please retry or use text instead.',
  'app.myBot.chat.speech.converting.text': 'Transcribing...',
  'app.myBot.chat.record.error': 'Mic off! Please review browser site settings.',
  'app.plugin.PluginCallSuccess': 'Plugin successfully invoked.',
  'app.plugin.PluginCallWait': 'Plugin invocation is waiting.',
  'app.plugin.PluginCallFail': 'Plugin invocation failed.',
  'app.myBot.chat.question.title': 'Try to ask',
  'app.home.embed.deny': 'Widgets should be embedded in a website for use',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.error':
    'Upload Error',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading':
    'Uploading',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading1':
    'Only jpg, png, gif, bmp, webp format images can be uploaded',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading2':
    'Image size cannot exceed 20MB',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading3':
    'Support uploading up to {imageLimit} images at most',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading4':
    'The selected model version for this BOT does not support image input',
};
