import { useRequest } from '@/hooks';
import { takeEmbedConfig } from '@/api/gen/bot-share-controller';
import { ref } from 'vue';
import { BotEmbedShareConfig } from '@/api/gen/model';
import { setStorage } from '@/utils/storage';
export interface EmbedConfigInfo extends BotEmbedShareConfig {
  errorMsg?: string;
}
// 获取嵌入Bot配置
const embedConfig = ref<EmbedConfigInfo | undefined>(undefined);
export default () => {
  const getEmbedConfig = (callback?: (data: BotEmbedShareConfig | undefined) => void) => {
    const { loading } = useRequest(takeEmbedConfig, {
      onSuccess: ({ data }) => {
        embedConfig.value = data;
        setStorage({
          key: 'i18n_redirected',
          value: data.locale,
        });
        callback && callback(data);
      },
      onError: (res: any) => {
        embedConfig.value = { errorMsg: res.message };
        callback && callback(undefined);
      },
    });
    return { loading };
  };
  return { embedConfig, getEmbedConfig };
};
